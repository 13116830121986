import * as React from "react";
import './Header.scss';
import imgLogo from '../../images/Logo_ch_b.png';

const Header: React.FC = () => {

    return (
        <header className="header">
            <div className="contacts">Наші контакти:</div>
            <ul>
                <li className="phone-number">(068) 32-213-93</li>
                <li className="vl"/>
                <li className="mail">dovira@fora.ua</li>
            </ul>
            <div className="logo">
                <div className="img-wrapper">
                    <img src={imgLogo} className="Logo" alt="logo"/>
                </div>
            </div>
        </header>
    );
};

export default Header;
